import React from 'react';
import './ManualOrderRecepientComponent.css';
import {
    Text,
    Grid,
    Col,
    Title,
    TextInput,
    Dropdown,
    DropdownItem,
  } from "@tremor/react";
import { FullOrder } from '../../../interfaces/OrderInterfaces';

export default function ManualOrderRecepientComponent(
        {isSender, order, setOrder, reverseLogistic}:
        {
          isSender:boolean,
          order:FullOrder,
          setOrder:Function,
          reverseLogistic:boolean
        }
    ) {

  return (
    <div>
      <Title>Dados {isSender ? "remetente" : "destinatário"}</Title>
        <Grid numCols={2} className="gap-2">
          <Col numColSpan={1}>
            <Text>{isSender ? "Remetente" : "Destinatário"}</Text>
            <TextInput 
              className='max-w-md'
              value={isSender ? order?.sender : order?.receiver}
              onChange={(e) => {isSender ? 
                setOrder({...order, sender: e.target.value}) :
                setOrder({...order, receiver: e.target.value})}}
            />
          </Col>
          <Col numColSpan={1}>
            <Text>CPF/CNPJ</Text>
            <TextInput
              className='max-w-md'
              value={isSender ?
                order?.senderDocument : order?.receiverDocument}
              onChange={(e) => {isSender ?
                setOrder({...order, senderDocument: e.target.value}) :
                setOrder({...order, receiverDocument: e.target.value})}}
            />
          </Col>
          <Col numColSpan={1}>
            <Text>Telefone</Text>
            <TextInput
              className='max-w-md'
              value={isSender ?
                order?.senderTelephone : order?.receiverTelephone}
              onChange={(e) => {isSender ? 
                setOrder({...order, senderTelephone: e.target.value}) :
                setOrder({...order, receiverTelephone: e.target.value})}}
            />
          </Col>
          {(reverseLogistic && isSender) || (!reverseLogistic && !isSender) ?
          <Col numColSpan={1}>
            <Text>Email</Text>
            <TextInput
              placeholder="cliente@gmail.com"
              value={isSender ?
                order?.senderEmail : order?.receiverEmail}
              className='max-w-md'
              onChange={(e) => {isSender ?
                setOrder({...order, senderEmail: e.target.value}) :
                setOrder({...order, receiverEmail: e.target.value})}}
            />
          </Col> : ""}
          <Col numColSpan={1}>
            <Text>CEP</Text>
            <TextInput
              className='max-w-md'
              value={isSender ?
                order?.senderZIP : order?.receiverZIP}
              onChange={(e) => {isSender ?
                setOrder({...order, senderZIP: e.target.value}) :
                setOrder({...order, receiverZIP: e.target.value})}}
            />
          </Col>
          <Col numColSpan={1}>
            <Text>Logradouro</Text>
            <TextInput
              className='max-w-md'
              value={isSender ?
                order?.senderStreet : order?.receiverStreet}
              onChange={(e) => {isSender ?
                setOrder({...order, senderStreet: e.target.value}) :
                setOrder({...order, receiverStreet: e.target.value})}}
            />
          </Col>
          <Col numColSpan={1}>
            <Text>Número</Text>
            <TextInput
              className='max-w-md'
              value={isSender ?
                order?.senderStreetNumber : order?.receiverStreetNumber}
              onChange={(e) => {isSender ?
                setOrder({...order, senderStreetNumber: e.target.value}) :
                setOrder({...order, receiverStreetNumber: e.target.value})}}
            />
          </Col>
          <Col>
            <Text>Complemento</Text>
            <TextInput
              className='max-w-md'
              value={isSender ?
                order?.senderAddressNumber : order?.receiverAddressNumber}
              onChange={(e) => {isSender ?
                setOrder({...order, senderAddressNumber: e.target.value}) :
                setOrder({...order, receiverAddressNumber: e.target.value})}}
            />
          </Col>
          <Col>
            <Text>Cidade</Text>
            <TextInput
              className='max-w-md'
              value={isSender ?
                order?.senderCity : order?.receiverCity}
              onChange={(e) => {isSender ?
                setOrder({...order, senderCity: e.target.value}) :
                setOrder({...order, receiverCity: e.target.value})}}
            />
          </Col>
          <Col>
            <Text>Bairro</Text>
            <TextInput
              className='max-w-md'
              value={isSender ?
                order?.senderNeighborhood : order?.receiverNeighborhood}
              onChange={(e) => {isSender ?
                setOrder({...order, senderNeighborhood: e.target.value}) :
                setOrder({...order, receiverNeighborhood: e.target.value})}}
            />
          </Col>
          <Col>
            <Text>Estado</Text>
            <Dropdown
              className='max-w-md'
              value={(isSender ?
                order?.senderState : order?.receiverState
                ) ?? "SP"}
              onValueChange={ (value) => {isSender ?
                setOrder({...order, senderState:value}) :
                setOrder({...order, receiverState:value})}}
            >
              <DropdownItem value="AC" text="Acre" />
              <DropdownItem value="AL" text="Alagoas" />
              <DropdownItem value="AP" text="Amapá" />
              <DropdownItem value="AM" text="Amazonas" />
              <DropdownItem value="BA" text="Bahia" />
              <DropdownItem value="CE" text="Ceará" />
              <DropdownItem value="DF" text="Distrito Federal" />
              <DropdownItem value="ES" text="Espírito Santo" />
              <DropdownItem value="GO" text="Goiás" />
              <DropdownItem value="AL" text="Alagoas" />
              <DropdownItem value="MA" text="Maranhão" />
              <DropdownItem value="MT" text="Mato Grosso" />
              <DropdownItem value="MS" text="Mato Grosso do Sul" />
              <DropdownItem value="MG" text="Minas Gerais" />
              <DropdownItem value="PA" text="Pará" />
              <DropdownItem value="PB" text="Paraíba" />
              <DropdownItem value="PR" text="Paraná" />
              <DropdownItem value="PE" text="Pernambuco" />
              <DropdownItem value="PI" text="Piauí" />
              <DropdownItem value="RJ" text="Rio de Janeiro" />
              <DropdownItem value="RN" text="Rio Grande do Norte" />
              <DropdownItem value="RS" text="Rio Grande do Sul" />
              <DropdownItem value="RO" text="Rondônia" />
              <DropdownItem value="RR" text="Roraima" />
              <DropdownItem value="SC" text="Santa Catarina" />
              <DropdownItem value="SP" text="São Paulo" />
              <DropdownItem value="SE" text="Sergipe" />
              <DropdownItem value="TO" text="Tocantins" />
            </Dropdown>
          </Col>
          <Col>
            <Text>Observação {isSender ? "remetente" : "destinatário"}</Text>
            <TextInput
              className='max-w-md'
              value={isSender ?
                order?.senderObservation : order?.receiverObservation}
              onChange={(e) => {isSender ?
                setOrder({...order, senderObservation: e.target.value}) :
                setOrder({...order, receiverObservation: e.target.value})}}
            />
          </Col>
        </Grid>
    </div>
  );
}