import React, { useState } from "react";
import { Button, Text, Dropdown, DropdownItem, TextInput, Title } from "@tremor/react";
import Dropzone from 'react-dropzone';
import { ArrowUpOnSquareIcon, DocumentCheckIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import './XMLOrderComponent.css';

export default function XMLOrderComponent(
  {setCreatedOrders, setSelectedTransporter, selectedTransporter}:
  {setCreatedOrders:Function, setSelectedTransporter:Function, selectedTransporter: string}
  ) {
    const [isFilesUploaded,setIsFilesUploaded] = useState<boolean>(false);
    const [files, setFiles] = useState<File[]>([new File([""], "filename")]);
    const [requestProcessing, setRequestProcessing] = useState(false);
    const [selectedCorreiosService, setSelectedCorreiosService] = useState<string>("SEDEX CONTRATO AG");

    const [cookies] = useCookies(['mazelog-user']);
    const token = cookies['mazelog-user']?.token_data?.access_token
    const oauth = "Bearer " + token

    const uploadHandler = (files:File[]) => {
        console.log(files);
        setFiles(files);
        setIsFilesUploaded(true);
    }

    const navigate = useNavigate();
    const createOrderXML = () => {
      setRequestProcessing(true);
      let form = new FormData();
      for (var i = 0; i < files.length; i++){
        form.append('files',files[i]);
      }
      
      const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;
      axios.post(`${baseURL}/order/save/XML`, form,
                {headers: {
                  "Content-Type": "multipart/form-data",
                  "Authorization": oauth,
                  "modal": selectedTransporter,
                  "service": selectedCorreiosService,
                }})
      .then((res) => {
        if (res?.data?.failed) {
          alert(JSON.stringify(res?.data?.failed));
        }
        if (res?.data?.data.length > 0) {
          setCreatedOrders(res?.data?.data);
          navigate('/orders');
        } else {
          setFiles([new File([""], "filename")]);
          setIsFilesUploaded(false);
          setRequestProcessing(false);
        }
      })
      .catch((err)=>{
        console.log(err);
        if (axios.isAxiosError(err) && err.response) {
          alert(err.response?.data?.detail);
        }
        setFiles([new File([""], "filename")]);
        setIsFilesUploaded(false);
        setRequestProcessing(false);
      });
    }

    return ( 
    <div>
      <div>
        <Text className='UploadTitle'>Selecionar forma de envio</Text>
        <Dropdown
            className='max-w-md'
            defaultValue="mazelog"
            onValueChange={ (value) => setSelectedTransporter(value) }
        >
          <DropdownItem value="mazelog" text="Mazelog" />
          <DropdownItem value="correios" text="Correios" />
        </Dropdown>
      </div>
      <br/>
      {selectedTransporter === 'correios' ? (
      <div>
        <Text className='UploadTitle'>Digite nome do serviço correios (Ex: SEDEX CONTRATO AG)</Text>
        <TextInput
          className='max-w-md'
          onChange={(e) => {setSelectedCorreiosService(e.target.value)}}
        />
        <br/>
      </div>
      ) : ""}
      <Text className='UploadTitle'>Arraste XML para criar pedido</Text>
      <Dropzone
        accept={{'application/xml':['.xml']}}
        multiple={true}
        onDrop={(acceptedFile) => uploadHandler(acceptedFile)}
      >
      {({getRootProps, getInputProps}) => (
      <section>
          <div {...getRootProps()}>
          <input {...getInputProps()} />
          {!isFilesUploaded ? <ArrowUpOnSquareIcon className='UploadIcon'/> : <DocumentCheckIcon className='UploadIcon'/>}
          </div>
      </section>
      )}
      </Dropzone>
      {!isFilesUploaded ? '' : <Text>{files[0]?.name + ' + ' + files.length + ' arquivos.'}</Text>}
      <br/>
      <Button
        disabled={!isFilesUploaded}
        loading={requestProcessing}
        onClick={createOrderXML}
        className='CreateOrderBtn'
      >Criar</Button>
    </div>
    );
}