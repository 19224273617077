import React, { useEffect } from 'react';
import { Card, TabList, Tab } from "@tremor/react";
import TitleComponent from "../components/TitleComponent";
import SidebarComponent from '../components/SidebarComponent';
import './CreateOrdersPage.css';
import XMLOrderComponent from '../components/createOrderComponents/XMLOrderComponent';
import ManualOrderComponent from '../components/createOrderComponents/ManualOrderComponent';
import SpreadsheetOrderComponent from '../components/createOrderComponents/SpreadsheetOrderComponent';
import { useCookies } from 'react-cookie';
import { FullOrder } from '../interfaces/OrderInterfaces';

export default function CreateOrdersPage(
  {
    manualBaseOrder,
    showCard,
    setShowCard,
    setCreatedOrders,
    setSelectedTransporter,
    selectedTransporter,
    reverseLogistic,
    setReverseLogistic,
  }:
  {
    manualBaseOrder:FullOrder|undefined,
    showCard:string,
    setShowCard:Function,
    setCreatedOrders:Function,
    setSelectedTransporter:Function,
    selectedTransporter:string,
    reverseLogistic: boolean,
    setReverseLogistic: Function,
  }
  ) {
  
  const [cookies] = useCookies(['mazelog-user']);
  const spreadsheetFlag = cookies['mazelog-user']?.organization_data?.spreadsheet;

  useEffect(() => {
    setSelectedTransporter('mazelog');
  }, [showCard])

  return (
    <main className="Page">
      <SidebarComponent />
      <div className="Content">
        <TitleComponent />
        <div className="OpsViews">
          <TabList
            defaultValue={showCard}
            onValueChange={(value) => setShowCard(value)}
          >
            <Tab value="0" text=".XML" className={showCard === "0" ? 'SelectedTab' : 'OtherTab'}/>
            <Tab value="1" text="Manual" className={showCard === "1" ? 'SelectedTab' : 'OtherTab'}/>
            {(spreadsheetFlag) ?
              (<Tab value="2" text="Planilha" className={showCard === "2" ? 'SelectedTab' : 'OtherTab'}/>) :
              (<div/>)}
          </TabList>
          <Card className="mt-6">
          {showCard === "0" ? (
            <div>
              <XMLOrderComponent
                setCreatedOrders={setCreatedOrders}
                setSelectedTransporter={setSelectedTransporter}
                selectedTransporter={selectedTransporter}
              />
            </div>
          ) : ("")}

          {showCard === "1" ? (
            <div>
              <ManualOrderComponent
                setCreatedOrders={setCreatedOrders}
                setSelectedTransporter={setSelectedTransporter}
                selectedTransporter={selectedTransporter}
                manualBaseOrder={manualBaseOrder}
                reverseLogistic={reverseLogistic}
                setReverseLogistic={setReverseLogistic}
              />
            </div>
          ) : ("")}

          {(showCard === "2")&&(spreadsheetFlag) ? (
            <div>
              <SpreadsheetOrderComponent
                setCreatedOrders={setCreatedOrders}
                setSelectedTransporter={setSelectedTransporter}
                selectedTransporter={selectedTransporter}
              />
            </div>
          ) : ("")}

          </Card>
        </div>
      </div>
    </main>
  );
}