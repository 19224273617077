import React, { useState } from "react";
import { Button, Text, Dropdown, DropdownItem, Title } from "@tremor/react";
import Dropzone from 'react-dropzone';
import { ArrowUpOnSquareIcon, DocumentCheckIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import './SpreadsheetOrderComponent.css'

export default function SpreadsheetOrderComponent(
  {setCreatedOrders, setSelectedTransporter, selectedTransporter}:
  {setCreatedOrders:Function, setSelectedTransporter:Function, selectedTransporter:string}
  ) {
    const [isFileUploaded,setIsFileUploaded] = useState<boolean>(false);
    const [file, setFile] = useState<File>(new File([""], "filename"));
    const [requestProcessing, setRequestProcessing] = useState(false);
    const [cookies] = useCookies(['mazelog-user']);

    const organizationData = cookies['mazelog-user']?.organization_data
    const token = cookies['mazelog-user']?.token_data?.access_token
    const oauth = "Bearer " + token

    const uploadHandler = (file:File) => {
        console.log(file);
        setFile(file);
        setIsFileUploaded(true);
    }

    const navigate = useNavigate();
    const createOrderSpreadsheet = () => {
      if (organizationData) {
        setRequestProcessing(true);
        let form = new FormData();
        form.append('file', file);

        form.append('sender', organizationData?.name);
        form.append('senderDocument', organizationData?.cnpj);
        form.append('senderStreet', organizationData?.street);
        form.append('senderStreetNumber', organizationData?.street_number);
        form.append('senderNeighborhood', organizationData?.neighborhood);
        form.append('senderCity', organizationData?.city);
        form.append('senderState', organizationData?.state);
        form.append('senderZIP', organizationData?.zip);
        form.append('senderTelephone', organizationData?.telephone);
        form.append('senderAddressNumber', organizationData?.address_number);

        const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL
        axios.post(`${baseURL}/order/save/batch`,form, 
                    {headers: {
                      "Content-Type": "multipart/form-data",
                      "Authorization": oauth,
                      "modal": selectedTransporter,
                    }})
        .then((res) => {
          setRequestProcessing(false);
          alert("Pedidos processados!");
          setIsFileUploaded(false);
          navigate('/batches');
        })
        .catch((err)=>{
          console.log(err);
          if (axios.isAxiosError(err) && err.response) {
            alert(err.response?.data?.detail);
          }
          setFile(new File([""], "filename"));
          setIsFileUploaded(false);
          setRequestProcessing(false);
        });
      }
    }

    return (
    <div>
      <div>
        <Text className='UploadTitle'>Selecionar forma de envio</Text>
        <Dropdown
            className='max-w-md'
            defaultValue="mazelog"
            onValueChange={ (value) => setSelectedTransporter(value) }
        >
          <DropdownItem value="mazelog" text="Mazelog" />
          <DropdownItem value="correios" text="Correios" />
        </Dropdown>
      </div>
      <br/>
      <Text className='UploadTitle'>Arraste planilha para criar pedido</Text>
      <Dropzone accept={{'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':['.xlxs']}} onDrop={(acceptedFile) => uploadHandler(acceptedFile[0])}>
      {({getRootProps, getInputProps}) => (
      <section>
          <div {...getRootProps()}>
          <input {...getInputProps()} />
          {!isFileUploaded ? <ArrowUpOnSquareIcon className='UploadIcon'/> : <DocumentCheckIcon className='UploadIcon'/>}
          </div>
      </section>
      )}
      </Dropzone>
      {!isFileUploaded ? '' : <Text>{file?.name}</Text>}
      <br/>
      <Button
        disabled={!isFileUploaded}
        loading={requestProcessing}
        onClick={createOrderSpreadsheet}
        className='CreateOrderBtn'
      >Criar</Button>
    </div>
    );
}