import React, { useState, useEffect } from 'react';
import './CorreiosDeclarationPage.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';

export default function CorreiosDeclarationPage({orderIds}:{orderIds:string[]}) {
    const [cookies] = useCookies(['mazelog-user']);
    const token = cookies['mazelog-user']?.token_data?.access_token;
    const oauth = "Bearer " + token;

    const [html, setHTML] = useState({__html: ""});

    let { ids } = useParams();
    
    useEffect(() => {
        if (ids) {
            const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL
            axios.post(`${baseURL}/correios/declaration`,
                        {data: ids.split(",")},
                        {headers: {"Authorization": oauth}})
                .then((res:any) => {
                    setHTML({__html:res.data});
                    setTimeout(() => {
                        window.print();
                    }, 1000);
                })
                .catch((err) =>
                    alert("Não foram encontradas declarações de conteúdo dos correios com os ids selecionados! Por favor, tente com outro ids.")
                )
        }
    }, [ids]);
    
    return <div dangerouslySetInnerHTML={html} />;
}