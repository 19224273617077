import React, { useState } from 'react';
import './SearchPage.css';
import {
  Tab,
  TabList
} from '@tremor/react';
import SidebarComponent from '../components/SidebarComponent';
import TitleComponent from '../components/TitleComponent';
import { Drivers } from '../interfaces/UserInterfaces';
import MazelogSearchComponent from '../components/searchComponents/MazelogSearchComponent';
import CorreiosSearchComponent from '../components/searchComponents/CorreiosSearchComponent';

export default function SearchPage(
    {drivers, selectedOrders, setSelectedOrders}:{drivers:Drivers, selectedOrders:string[], setSelectedOrders:Function}
) {
  const [showCard, setShowCard] = useState("0");

  return (
    <main className="Page">
      <SidebarComponent />
      <div className="Content">
        <TitleComponent />
        <div className="OpsViews">
          <TabList
            defaultValue="0"
            onValueChange={(value) => setShowCard(value)}
          >
            <Tab value="0" text="Envio Mazelog" className={showCard === "0" ? 'SelectedTab' : 'OtherTab'}/>
            <Tab value="1" text="Correios" className={showCard === "1" ? 'SelectedTab' : 'OtherTab'}/>
          </TabList>
        <div className="SearchViews">
          {showCard === "0" ? (
            <div className='w-full'>
              <MazelogSearchComponent
                drivers={drivers}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
              />
            </div>
          ) : ("")}

          {(showCard === "1")  ? (
            <div className='w-full'>
              <CorreiosSearchComponent
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
              />
            </div>
          ) : ("")}

        </div>
      </div>
      </div>
    </main>
  );
}