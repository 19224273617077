import React, { useEffect, useState } from 'react';
import './OrderDetailPage.css';
import SidebarComponent from '../components/SidebarComponent';
import TitleComponent from "../components/TitleComponent";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate, Link } from 'react-router-dom';
import { FullOrder, OrderSources } from '../interfaces/OrderInterfaces';
import OrderComponent from '../components/OrderComponent';
import { 
    Button,
    Flex,
    Metric,
    Card,
    Grid,
    Col,
    Divider,
    Title,
    Text,
    Table,
    TableHeaderCell,
    TableHead,
    TableRow,
    TableBody,
    TableCell
} from '@tremor/react';
import  PDFStickerComponent from '../components/stickerComponents/MazelogStickerComponent';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { Drivers } from '../interfaces/UserInterfaces';
import OrderHistoryComponent from '../components/OrderHistoryComponent';

export default function OrderDetailPage(
        {
            drivers,
            setShowCard,
            setManualBaseOrder,
            setReverseLogistic
        }:
        {
            drivers:Drivers,
            setShowCard:Function,
            setManualBaseOrder:Function,
            setReverseLogistic:Function
        }
    ) {
    const [cookies] = useCookies(['mazelog-user']);
    const productFlag = cookies['mazelog-user']?.organization_data?.product_flag;
    const token = cookies['mazelog-user']?.token_data?.access_token;
    const oauth = "Bearer " + token;
    const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;

    const meliStatusTranslate: { [key: string]: string } = {
        "handling": "Em manejo",
        "ready_to_ship": "Pronto para envio",
        "shipped": "A caminho",
        "first_visit": "Primeira visita",
        "delivered": "Entregue"
    }

    let { id } = useParams();
    const [order, setOrder] = useState<FullOrder>();
    const [orderUpdated, setOrderUpdated] = useState<boolean>(false);
    const [correiosOrders, setCorreiosOrders] = useState<boolean>(false);
    const [reverseLogisticOrder, setReverseLogisticOrder] = useState<boolean>(false);
    const [stickerButtonDisabled, setStickerButtonDisabled] = useState<boolean>(true);
    const [declarationButtonDisabled, setDeclaraionButtonDisabled] = useState<boolean>(false);
    const [cancelButtonDisabled, setCancelButtonDisabled] = useState<boolean>(false);
    const [correiosTagDownloading, setCorreiosTagDownloading] = useState<boolean>(false);
    const [meliTagDownloading, setMeliTagDownloading] = useState<boolean>(false);
    const [meliOrderDelivered, setMeliOrderDelivered] = useState<boolean>(false);

    const navigate = useNavigate();
    useEffect(()=>{
        axios.get(`${baseURL}/order/${id}`,
            {headers: {"Authorization": oauth}})
            .then((res) => {
                console.log(res);
                setOrder(res.data);
                setOrderUpdated(false);
            })
            .catch((err) => {
                console.log(err);
                navigate(`/`);
            })
    },[id,orderUpdated])

    useEffect(() => {
        if (!!order?.mercado_livre) {
            setMeliOrderDelivered(
                !!order?.mercado_livre?.events.filter(x => x.type === "delivered")
            )
        }
    }, [order])

    useEffect(()=>{
        if (order?.transporter === "correios") {
            setCorreiosOrders(true);
        }
        setReverseLogisticOrder(!!order?.reverseLogistic);
    },[order])

    const cancelOrder = async () => {
        if (window.confirm("Deseja mesmo cancelar este pedido?")){
            axios.delete(`${baseURL}/order/${id}`,{headers: {"Authorization": oauth}})
            .then((res) => {
                console.log(res);
                setOrderUpdated(true);
            })
            .catch((err) => {
                console.log(err);
                if (axios.isAxiosError(err) && err.response) {
                    alert(err.response?.data?.detail);
                }
            })
        }
    }

    const downloadCorreiosStickerTag = () => {
        setCorreiosTagDownloading(true);
        axios.post(`${baseURL}/correios/tag`,
                    {
                    data: [id],
                    "product_flag": productFlag,
                    },
                    {
                        headers: {
                            "Authorization": oauth,
                        },
                        responseType: 'blob'
                    })
            .then((response) => new Blob([response.data]))
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `etiqueta_correios_${id}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                })
            .catch((err) =>
                alert("Etiqueta dos correios ainda está sendo gerada, tente mais tarde.")
            )
            .finally(() => setCorreiosTagDownloading(false))
    }

    const downloadMercadoLivreStickerTag = () => {
        setMeliTagDownloading(true);
        axios.post(`${baseURL}/mercado_livre/tag`,
            {order_id: id},
            {
                headers: {
                    "Authorization": oauth,
                },
                responseType: 'blob'
            })
            .then((res:any) => {
                var urlCreator = window.URL || window.webkitURL;
                let imageUrl = urlCreator.createObjectURL(res.data);
                window.open(imageUrl, '_blank', 'noopener,noreferrer');
            })
            .catch((err) =>
                alert("Etiqueta do mercado livre ainda está sendo gerada, tente mais tarde.")
            )
            .finally(() => setMeliTagDownloading(false))
    }

    const reverseLogisticHandler = () => {
        setShowCard("1");
        setManualBaseOrder(order);
        setReverseLogistic(true);
        navigate(`/create`);
    }

    const convertDate = (date:string, subtract_hours:number) => {
        const pos = date.indexOf("+");
        if (pos !== -1) {
            date = date.substring(0, pos);
        }
        let newDate = new Date(date);
        newDate.setHours(newDate.getHours() - subtract_hours);
        return newDate.toLocaleString('pt-BR');
    }

    useEffect(() => {
        //download sticker
        setStickerButtonDisabled(!(
            order?.correios &&
            order?.correios?.statusPostagem[order?.correios?.statusPostagem.length - 1].description === "Pré-postado" &&
            (new Date(order?.correios?.prazoPostagem) > new Date())
        ));

        //declaration
        setDeclaraionButtonDisabled(false);

        //cancel
        setCancelButtonDisabled(Boolean(order) &&
            Boolean(['delivered', 'cancelled'].includes(order!.events[order!.events.length - 1].type))
        );
    }, [order])

  return (
    <main>
        <SidebarComponent />
        <div className='OrderContent'>
            <TitleComponent />
            <div>
                <Flex justifyContent='end'>
                    {order ? 
                    (
                    <Flex justifyContent='end'>
                        {!order?.reverseLogistic ?
                        <div>
                            <Button
                                className='DownloadBtn'
                                onClick={reverseLogisticHandler}
                            >
                            Criar etiqueta logística reversa
                            </Button>
                        </div> : ""}
                        {order.transporter !== 'correios' ?
                        <div>
                            {order.source === OrderSources.MAZELOG ?
                                (<Button className='DownloadBtn'>
                                    <PDFDownloadLink
                                        document={<PDFStickerComponent orderList={[order]} productFlag={productFlag} />}
                                        fileName={`${order?.orderNumber}.pdf`}
                                    >
                                    Baixar etiqueta Mazelog
                                    </PDFDownloadLink>
                                </Button>) :
                                (
                                    <Button
                                        className='DownloadBtn'
                                        onClick={downloadMercadoLivreStickerTag}
                                        loading={meliTagDownloading}
                                        disabled={meliOrderDelivered}
                                    >Baixar etiqueta Mercado Livre</Button>
                                )}
                        </div>
                        : ""}
                        {(correiosOrders && !reverseLogisticOrder) ?
                            <div>
                                <Button
                                    className='DownloadBtn'
                                    disabled={stickerButtonDisabled}
                                    onClick={downloadCorreiosStickerTag}
                                    loading={correiosTagDownloading}
                                >Baixar etiqueta Correios</Button>
                                <Link to={`/orders/declaration/${order?.orderNumber}`} target="_blank" rel="noopener noreferrer">
                                    <Button
                                        className='DownloadBtn'
                                        disabled={declarationButtonDisabled}
                                    >Baixar declaração de conteúdo</Button>
                                </Link>
                            </div> : ""}
                        <Link to={`/occurrence/${order.orderNumber}`}>
                            <Button className='DownloadBtn'>Abrir ocorrência</Button>
                        </Link>
                        <Button
                            className='DownloadBtn'
                            onClick={cancelOrder}
                            disabled={cancelButtonDisabled}
                        >Cancelar pedido</Button>
                    </Flex>) :
                    (<div>
                    <Button className='DownloadBtn' disabled={true}>Cancelar pedido</Button>
                    <Button className='DownloadBtn' disabled={true}>Abrir ocorrência</Button>
                    </div>)}
                </Flex>
            </div>
            <div className="DashboardViews">
                <OrderComponent order={order} />
            </div>
            <div>
                <OrderHistoryComponent order={order} drivers={drivers} />
            </div>
            <br/>
            <div>
                {(order)&&(order.transporter !== 'correios')&&(order.source === OrderSources.MAZELOG) ? 
                (
                <div>
                    <Card>
                        <Metric>Etiqueta</Metric>
                        <PDFViewer className='PDFCard' showToolbar={false}>
                            <PDFStickerComponent orderList={[order]} productFlag={productFlag} />
                        </PDFViewer>
                    </Card>
                </div>
                ) :
                ('')}
            </div>
            <div>
                {(order?.source === OrderSources.MERCADO_LIVRE && !!order.mercado_livre) ?
                (<div>
                    <Card>
                        <Metric>Informações do pedido do Canal</Metric>
                        <Grid numCols={2} className="gap-2">
                            <Col numColSpan={1}>
                                <Title>Canal</Title>
                                <Text className='max-w-md'>Mercado Livre</Text>
                            </Col>
                            <Col numColSpan={1}>
                                <Title>ID do pedido</Title>
                                <Text className='max-w-md'>{order?.mercado_livre?.order_id}</Text>
                            </Col>
                            <Col numColSpan={1}>
                                <Title>ID da entrega</Title>
                                <Text className='max-w-md'>{order?.mercado_livre?.shipping_id}</Text>
                            </Col>
                            <Col numColSpan={1}>
                                <Title>Seller ID</Title>
                                <Text className='max-w-md'>{order?.mercado_livre?.seller_id}</Text>                  
                            </Col>
                            <Col numColSpan={1}>
                                <Title>Substatus do pedido</Title>
                                <Text>{order?.mercado_livre?.order_status}</Text>
                            </Col>
                            <Col numColSpan={1}>
                                <Title>Descrição do serviço</Title>
                                <Text>{order?.mercado_livre?.serviceDescription}</Text>
                            </Col>
                            <Col numColSpan={1}>
                                <Title>Data de criação</Title>
                                <Text>{
                                order?.mercado_livre?.date_created ?
                                convertDate(order?.mercado_livre?.date_created.toString(), 0):
                                ""}</Text>
                            </Col>
                            <Col numColSpan={1}>
                                <Title>Data de entrega prevista</Title>
                                <Text>{
                                order?.mercado_livre?.estimated_delivery_date ?
                                convertDate(order?.mercado_livre?.estimated_delivery_date?.toString(), 0).substring(0, 10) :
                                ""}</Text>
                            </Col>
                        </Grid>
                        <Divider/>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeaderCell>Status</TableHeaderCell>
                                    <TableHeaderCell>Data</TableHeaderCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                { order?.mercado_livre?.events
                                    .map((event:any) => (
                                        <TableRow key={ event?.type }>
                                        <TableCell className="TableField">{ meliStatusTranslate[event?.type] }</TableCell>
                                        <TableCell className="TableField">{ convertDate(event?.date, 0) }</TableCell>
                                        </TableRow>
                                    )) }
                            </TableBody>
                        </Table>
                    </Card>
                </div>) : ('')}
            </div>
        </div>
    </main>
  );
}