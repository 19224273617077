import React from 'react';
import './OrderDetailBlockComponent.css';
import { Text, Title, Grid, Col } from "@tremor/react";
import { FullOrder } from '../interfaces/OrderInterfaces';

function OrderDetailBlockComponent(
    {order, sender, reverseLogistic} :
    {order:FullOrder|undefined, sender:boolean, reverseLogistic:boolean}
  ) {
  return (
    <div>
        <Grid numCols={5} className="gap-2">
            <Col numColSpan={1}>
                <Title>{sender ? 'Remetente' : 'Destinatário'}</Title>
                <Text className='max-w-md'>{sender ? order?.sender : order?.receiver}</Text>
            </Col>
            <Col numColSpan={1}>
                <Title>CPF/CNPJ</Title>
                <Text className='max-w-md'>{sender ? order?.senderDocument : order?.receiverDocument}</Text>
            </Col>
            <Col numColSpan={1}>
                <Title>CEP</Title>
                <Text className='max-w-md'>{sender ? order?.senderZIP : order?.receiverZIP}</Text>
            </Col>
            <Col numColSpan={1}>
                <Title>Cidade</Title>
                <Text className='max-w-md'>{sender ? order?.senderCity : order?.receiverCity}</Text>                  
            </Col>
            <Col numColSpan={1}>
                <Title>Estado</Title>
                <Text>{sender ? order?.senderState : order?.receiverState}</Text>
            </Col>
            <Col numColSpan={1}>
                <Title>Logradouro</Title>
                <Text>{sender ? order?.senderStreet : order?.receiverStreet}</Text>
            </Col>
            <Col numColSpan={1}>
                <Title>Número</Title>
                <Text>{sender ? order?.senderStreetNumber : order?.receiverStreetNumber}</Text>
            </Col>
            <Col numColSpan={1}>
                <Title>Complemento</Title>
                <Text>{sender ? order?.senderAddressNumber : order?.receiverAddressNumber}</Text>
            </Col>
            <Col numColSpan={1}>
                <Title>Telefone</Title>
                <Text>{sender ? order?.senderTelephone : order?.receiverTelephone}</Text>
            </Col>
            {(reverseLogistic && sender) || (!reverseLogistic && !sender) ? 
            <Col numColSpan={1}>
                <Title>Email</Title>
                <Text>{!reverseLogistic ? order?.receiverEmail : order?.senderEmail}</Text>
            </Col> : ""}
            <Col numColSpan={1}>
                <Title>Obs. {sender ? 'Remetente' : 'Destinatário'} </Title>
                <Text>{sender ? order?.senderObservation : order?.receiverObservation}</Text>
            </Col>
        </Grid>
    </div>
  );
}

export default OrderDetailBlockComponent;