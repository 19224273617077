export type CorreiosStatus = {
  code: number;
  description: string;
  date: string;
}

export type Correios = {
  idPrepostagem?: string;
  codigoRastreio: string;
  statusPostagem: CorreiosStatus[];
  prazoPostagem: string;
  serviceCode: string;
  serviceDescription: string;
  idTag?: string;
}

export type MercadoLivreEvents = {
  date: Date;
  type: string;
}

export type MercadoLivreOrderItem = {
  value: number;
  product: string;
  quantity: number;
}

export type MercadoLivreVol = {
  product: string;
  height: number;
  length: number;
  width: number;
  amount: number;
  weight: number;
}

export type MercadoLivre = {
  order_id: number;
  shipping_id: number;
  date_created: Date;
  order_items: MercadoLivreOrderItem[];
  order_status: string;
  serviceDescription: string;
  seller_id: number;
  estimated_delivery_date?: Date;
  events: MercadoLivreEvents[];
  vols: MercadoLivreVol[];
}

export type Coordinates = {
  latitude: string;
  logitude: string;
}

export type OrderEvents = {
  type: string;
  date: string;
  id_user?: number;
  id_org?: number;
  coordinates?: Coordinates;
};

export enum OrderSources {
  MAZELOG = 'mazelog',
  MERCADO_LIVRE = 'mercado_livre',
};

export type FullOrder = {
  orderNumber: string | undefined;
  status: string;
  receiptNumber: string;
  sender: string;
  senderDocument: string;
  senderTelephone: string;
  senderEmail?: string;
  senderZIP: string;
  senderStreet: string;
  senderStreetNumber: string;
  senderAddressNumber: string;
  senderNeighborhood: string;
  senderCity: string;
  senderState: string;
  senderObservation: string;
  receiver: string;
  receiverDocument: string;
  receiverTelephone: string;
  receiverEmail: string;
  receiverZIP: string;
  receiverStreet: string;
  receiverStreetNumber: string;
  receiverAddressNumber: string;
  receiverNeighborhood: string;
  receiverCity: string;
  receiverState: string;
  receiverObservation: string;
  product: string;
  amount: string;
  height: string;
  width: string;
  length: string;
  weigth: string;
  value: string;
  transporter: string;
  date: Date;
  events: OrderEvents[];
  collecterName: string;
  collecterDocument: string;
  collecterRelationship?: string;
  collecterImage?: string;
  observation: string;
  observationType?: string;
  correios?: Correios;
  mercado_livre?: MercadoLivre;
  qr_code?: string;
  reverseLogistic: boolean;
  source: OrderSources;
};

export type ShortOrder = {
  orderNumber: string;
  receiptNumber: string;
  status: string;
  sender: string;
  senderDocument: number;
  receiver: string;
  receiverZIP: string;
  receiverDocument: number;
  transporter: string;
  creationDate: Date;
  lastStatusDate: Date;
  occurrenceOpenDate?: Date;
  source: OrderSources;
};