import React, { useState, useEffect } from 'react';
import './OccurrenceListPage.css';
import {
  Button,
  Dropdown,
  DropdownItem,
  DateRangePickerValue,
  Card,
  Grid,
  Col,
  Title,
  TextInput,
  DateRangePicker,
  Divider,
  Flex,
} from "@tremor/react";
import SidebarComponent from '../../components/SidebarComponent';
import TitleComponent from '../../components/TitleComponent';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { ptBR } from "date-fns/locale";
import { ShortOrder } from '../../interfaces/OrderInterfaces';
import OccurrenceListTableComponent from '../../components/occurrenceComponents/OccurrenceListTableComponent';

export default function OccurrenceListPage() {
    const [cookies] = useCookies(['mazelog-user']);
    const token = cookies['mazelog-user']?.token_data?.access_token;

    const now = new Date();

    const [requested, setRequested] = useState<boolean>(false);
    const [occurrences, setOccurrences] = useState<ShortOrder[]>([]);
    const [page, setPage] = useState<number>(0);
    const [maxPage, setMaxPage] = useState<number>(0);
    const [selectedStatus, setSelectedStatus] = useState<string>('open_unreplied');
    const [selectedDateRange, setSelectedDateRange] = useState<DateRangePickerValue>([
      new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
      now
    ]);
    const [selectedNumber, setSelectedNumber] = useState<string>('');
    const [selectedReceipt, setSelectedReceipt] = useState<string>('');
    const [selectedReceiver, setSelectedReceiver] = useState<string>('');
    const [selectedOrderStatus, setSelectedOrderStatus] = useState<string>('all');

    const handleSubmit = () => {
      const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;
      let URL = `${baseURL}/order/occurrences/query/${selectedStatus}?page=${page}&occurrence_start_date=${selectedDateRange[0]?.toISOString()}&occurrence_end_date=${selectedDateRange[1]?.toISOString()}`;
      if(selectedNumber !== ''){URL += `&order_number=${selectedNumber}`}
      if(selectedReceipt !== ''){URL += `&receipt_number=${selectedReceipt}`}
      if(selectedOrderStatus !== 'all'){URL += `&order_status=${selectedOrderStatus}`}
      if(selectedReceiver !== ''){URL += `&receiver_document=${selectedReceiver.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\s]/g,"")}`}
      const oauth = "Bearer " + token;
      axios.get(URL,{headers: {accept: 'application/json', "Authorization": oauth}})
      .then((res)=>{
        setOccurrences(res.data.results);
        setPage(res.data.pages.current);
        setMaxPage(res.data.pages.max);
      })
      .catch((err)=>{
        console.log(err);
      })
    }

    useEffect(()=>{
      if (!requested){
        handleSubmit();
        setRequested(true);
      }
    },[requested])

    const searchButton = () => {
      setPage(0);
      setRequested(false);
    }

    const pageForward = () => {
      setPage(page + 1);
      setRequested(false);
    }

    const pageBack = () => {
      setPage(page - 1);
      setRequested(false);
    }

  return (
    <main className="Page">
      <SidebarComponent />
      <div className="Content">
        <TitleComponent />
        <div className="OpsViews">
          <Card>
            <Grid numCols={2} className="gap-2">
              <Col numColSpan={1}>
                <Title>Status ocorrência</Title>
                <Dropdown
                  className='max-w-md'
                  defaultValue="open_unreplied"
                  onValueChange={ (value) => setSelectedStatus(value) }
              >
                  <DropdownItem value="open_unreplied" text="Ocorrências abertas a responder" />
                  <DropdownItem value="open_replied" text="Ocorrências abertas respondidas" />
                  <DropdownItem value="cancelled" text="Ocorrências canceladas" />
                  <DropdownItem value="solved" text="Ocorrências concluídas" />
              </Dropdown>
              </Col>
              <Col numColSpan={1}>
                <Title>ID do pedido</Title>
                <TextInput
                  className='max-w-md'
                  onChange={(e) => setSelectedNumber(e.target.value)}
                />
              </Col>
              <Col numColSpan={1}>
                <Title>Número da nota</Title>
                <TextInput
                  placeholder='0000000000'
                  className='max-w-md'
                  onChange={(e) => setSelectedReceipt(e.target.value)}
                />
              </Col>
              <Col numColSpan={1}>
                <Title>Status pedido</Title>
                <Dropdown
                    className='max-w-md'
                    defaultValue="all"
                    onValueChange={ (value) => setSelectedOrderStatus(value) }
                >
                    <DropdownItem value="all" text="Todos os status" />
                    <DropdownItem value="created" text="Criado" />
                    <DropdownItem value="transporter_allocated" text="Transportador alocado" />
                    <DropdownItem value="collected" text="Coletado" />
                    <DropdownItem value="received" text="Recebido" />
                    <DropdownItem value="en_route" text="Em rota" />
                    <DropdownItem value="delivered" text="Entregue" />
                    <DropdownItem value="cancelled" text="Cancelado" />
                    <DropdownItem value="handling" text="Em tratativa" />
                    <DropdownItem value="awaiting_return" text="Aguard. devolução" />
                    <DropdownItem value="awaiting_transfer" text="Aguard. transferência" />
                    <DropdownItem value="return_route" text="Rota devolução" />
                    <DropdownItem value="returned" text="Devolvido" />
                    <DropdownItem value="collected_not_received" text="Coletados Não Receb." />
                    <DropdownItem value="cancelled_after_received" text="Canc. após Receb." />
                    <DropdownItem value="returned_area_not_serviced" text="Devolvido - Área não atendida" />
                    <DropdownItem value="new_driver_allocated" text="Novo entregador alocado" />
                </Dropdown>
              </Col>
              <Col numColSpan={1}>
                <Title>CNPJ/CPF destinatário</Title>
                <TextInput
                  placeholder="00000000000000"
                  className='max-w-md'
                  onChange={(e) => setSelectedReceiver(e.target.value)}
                />                  
              </Col>
              <Col numColSpan={1}>
                <Title>Data da ocorrência</Title>
                <DateRangePicker
                  className="max-w-md"
                  value={selectedDateRange}
                  onValueChange={setSelectedDateRange}
                  locale={ptBR}
                  dropdownPlaceholder="Selecionar"
                  enableYearPagination={true}
                />
              </Col>
              <Col numColSpan={1}>
                <Button className='FilterButton' onClick={searchButton}>
                  Filtrar
                </Button>
              </Col>
              <Col numColSpan={1} className='max-w-[200px]'>
                <Flex flexDirection='row'>
                  <Button
                    className='OpenOrderBtn'
                    disabled={page === 0}
                    onClick={pageBack}
                  >
                    {"<"}
                  </Button>
                  <Title>
                    Página {page+1} de {maxPage+1}
                  </Title>
                  <Button
                    className='OpenOrderBtn'
                    disabled={page === maxPage}
                    onClick={pageForward}
                  >
                    {">"}
                  </Button>
                </Flex>
              </Col>
            </Grid>
            <Divider className='h-1'/>
            <OccurrenceListTableComponent occurrences={occurrences}/>
          </Card>
        </div>
      </div>
    </main>
  );
}