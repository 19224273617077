import React, { useEffect, useState } from 'react';
import './StickerPage.css';
import SidebarComponent from '../components/SidebarComponent';
import TitleComponent from "../components/TitleComponent";
import PDFStickerComponent from '../components/stickerComponents/MazelogStickerComponent';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate, Link } from 'react-router-dom';
import { FullOrder } from '../interfaces/OrderInterfaces';
import { Button, Flex } from '@tremor/react';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';


export default function StickerPage(
    {orderIds, setReverseLogistic}:{orderIds:string[], setReverseLogistic:Function}
    ) {
    const [orders, setOrders] = useState<FullOrder[]>();
    const [correiosOrders, setCorreiosOrders] = useState<boolean>(false);
    const [cookies] = useCookies(['mazelog-user']);
    const productFlag = cookies['mazelog-user']?.organization_data?.product_flag
    const token = cookies['mazelog-user']?.token_data?.access_token
    const oauth = "Bearer " + token;
    const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;

    const navigate = useNavigate();
    const getMazelogStickerData = () => {
        axios.post(`${baseURL}/order/batch`,
                    {data:orderIds},
                    {headers: {"Authorization": oauth}})
        .then((res) => {
            console.log(res);
            setOrders(res.data.results);
        })
        .catch((err) => {
            console.log(err);
            navigate(`/`);
        })
    }

    const downloadCorreiosStickerTag = () => {
        axios.post(`${baseURL}/correios/tag`,
                    {
                    data: orderIds,
                    "product_flag": productFlag,
                    },
                    {
                        headers: {
                            "Authorization": oauth,
                        },
                        responseType: 'blob'
                    })
            .then((response) => new Blob([response.data]))
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `etiqueta_correios_${orderIds}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                })
            .catch((err) =>
                alert("Etiqueta dos correios ainda está sendo gerada, tente mais tarde.")
            )
    }
    
    useEffect(()=>{
        setReverseLogistic(false);
        getMazelogStickerData();
    },[orderIds])

    useEffect(()=>{
        orders?.forEach((order) => {
            if (order.transporter === "correios") {
                setCorreiosOrders(true);
            }
        })
    },[orders])

  return (
    <main>
        <SidebarComponent />
        <div className='OrderContent'>
            <TitleComponent />
            {orders ? (
                <Flex justifyContent='end'>
                    {!correiosOrders ?
                    (<Button className='DownloadBtn'>
                        <PDFDownloadLink
                            document={
                                <PDFStickerComponent orderList={orders} productFlag={productFlag} />
                            }
                            fileName={`${new Date()}.pdf`}
                            >
                        Baixar etiqueta
                        </PDFDownloadLink>
                    </Button>)
                    : (
                    <div>
                        <Button
                            className='DownloadBtn'
                            onClick={downloadCorreiosStickerTag}
                        >Baixar etiqueta Correios</Button>
                        <Link to={`/orders/declaration/${orderIds}`} target="_blank" rel="noopener noreferrer">
                            <Button className='DownloadBtn'>Baixar declaração de conteúdo</Button>
                        </Link>
                    </div>)}
                </Flex>
            ) : (
                <Flex justifyContent='end'>
                    <Button className='DownloadBtn' disabled={true}>Baixar etiqueta</Button>
                    {correiosOrders ?
                    <div>
                    <Button className='DownloadBtn' disabled={true}>Baixar etiqueta Correios</Button>
                    <Button className='DownloadBtn' disabled={true}>Baixar declaração de conteúdo</Button>
                    </div> : ""}
                </Flex>
            )}
            <div className="DashboardViews">
                {orders ?
                    ((!correiosOrders) ? 
                    (<PDFViewer className='PDFCard' showToolbar={false}>
                        <PDFStickerComponent orderList={orders} productFlag={productFlag} />
                    </PDFViewer>) :
                    (<div>
                        <img src={require('../images/correios-logomarca-5.webp')} alt='correios logo' />
                    </div>)) :
                    ""}
            </div>
        </div>
    </main>
  );
}