import React from 'react';
import './OccurrenceListTableComponent.css';
import {
    Table,
    TableHead,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    Button,
  } from "@tremor/react";
import { Link } from "react-router-dom";
import { ShortOrder } from '../../interfaces/OrderInterfaces';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';

export default function OccurrenceListTableComponent(
    {occurrences}:{occurrences:ShortOrder[]}
) {
    const convertDate = (date:Date|undefined) => {
        if (!date) {
            return ""
        } else {
            let newDate = new Date(date);
            newDate.setHours(newDate.getHours() - 3);
            let newDateStr = newDate.toLocaleString('en-GB').substring(0, 10);
            return newDateStr
        }
    }

    const truncateString = (text:string, maxLength: number):string => {
        if (text.length <= maxLength) {
            return text
        } else {
            return text.substring(0, maxLength) + '...'
        }
    }

    const statusTranslate: { [key: string]: string } = {
        "created": "Criado",
        "transporter_allocated": "Transportador alocado",
        "collected": "Coletado",
        "received": "Recebido",
        "en_route": "Em rota",
        "delivered": "Entregue",
        "cancelled": "Cancelado",
        "handling": "Em tratativa",
        "awaiting_return": "Aguard. devolução",
        "awaiting_transfer": "Aguard. transferência",
        "return_route": "Rota devolução",
        "returned": "Devolvido",
        "collected_not_received": "Coletados Não Receb.",
        "cancelled_after_received": "Canc. após Receb.",
        "returned_area_not_serviced": "Devolvido - Área não atendida",
        "new_driver_allocated": "Novo entregador alocado"
    }

    return (
    <div>
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>ID do pedido</TableHeaderCell>
                    <TableHeaderCell className="text-left">Número nota</TableHeaderCell>
                    <TableHeaderCell className="text-left">Data pedido</TableHeaderCell>
                    <TableHeaderCell className="text-left">Data ocorrência</TableHeaderCell>
                    <TableHeaderCell className="text-left">Status</TableHeaderCell>
                    <TableHeaderCell className="text-left">Remetente</TableHeaderCell>
                    <TableHeaderCell className="text-left">Destinatário</TableHeaderCell>
                    <TableHeaderCell className="text-left">CEP Dest</TableHeaderCell>
                    <TableHeaderCell className="text-left">Transportador</TableHeaderCell>
                </TableRow>
            </TableHead>

            <TableBody>
                { occurrences
                    .map((occurrence) => (
                        <TableRow key={ occurrence.orderNumber }>
                            <TableCell className="TableField">{ occurrence.orderNumber }</TableCell>
                            <TableCell className="TableField">{ occurrence.receiptNumber }</TableCell>
                            <TableCell className="TableField">{ convertDate(occurrence.creationDate) }</TableCell>
                            <TableCell className="TableField">{ convertDate(occurrence?.occurrenceOpenDate) }</TableCell>
                            <TableCell className="TableField">{ statusTranslate[occurrence.status] }</TableCell>
                            <TableCell className="TableField">{ truncateString(occurrence.sender, 10) }</TableCell>
                            <TableCell className="TableField">{ truncateString(occurrence.receiver, 20) }</TableCell>
                            <TableCell className="TableField">{ occurrence.receiverZIP }</TableCell>
                            <TableCell className="TableField">{ occurrence.transporter }</TableCell>
                            <Link to={`/occurrence/${occurrence.orderNumber}`}>
                                <Button className='OpenOrderBtn' icon={ArrowUpRightIcon} />
                            </Link>
                        </TableRow>
                    )) }
            </TableBody>
        </Table>
    </div>
  );
}