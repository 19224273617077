import React from 'react';
import './OrderHistoryComponent.css';
import { useCookies } from 'react-cookie';
import { Card, Table, TableHead, TableBody, TableCell, TableRow, TableHeaderCell, Metric, Button, Flex } from '@tremor/react';
import { FullOrder } from '../interfaces/OrderInterfaces';
import { Drivers } from '../interfaces/UserInterfaces';

export default function OrderHistoryComponent({order, drivers}:{order:FullOrder|undefined, drivers:Drivers}) {
    const [cookies] = useCookies(['mazelog-user']);
    const profile = cookies['mazelog-user']?.organization_data?.profile;

    const statusTranslate: { [key: string]: string } = {
        "created": "Criado",
        "transporter_allocated": "Transportador alocado",
        "collected": "Coletado",
        "received": "Recebido",
        "en_route": "Em rota",
        "delivered": "Entregue",
        "cancelled": "Cancelado",
        "handling": "Em tratativa",
        "awaiting_return": "Aguard. devolução",
        "awaiting_transfer": "Aguard. transferência",
        "return_route": "Rota devolução",
        "returned": "Devolvido",
        "collected_not_received": "Coletados Não Receb.",
        "cancelled_after_received": "Canc. após Receb.",
        "returned_area_not_serviced": "Devolvido - Área não atendida",
        "new_driver_allocated": "Novo entregador alocado"
    }

    const convertDate = (date:string) => {
        const pos = date.indexOf("+");
        if (pos !== -1) {
            date = date.substring(0, pos);
        }
        let newDate = new Date(date);
        newDate.setHours(newDate.getHours() - 3);
        return newDate.toLocaleString('pt-BR');
    }

  return (
    <Card>
        <Metric>Histórico do pedido</Metric>
        { (order?.transporter === "correios")&&(order.correios?.statusPostagem.length) ?
            (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell>Código Correios</TableHeaderCell>
                            <TableHeaderCell>Status</TableHeaderCell>
                            <TableHeaderCell>Data</TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {order.correios.statusPostagem.map((event) => (
                        <TableRow key={ event?.code }>
                            <TableCell className="TableField">{ event?.code }</TableCell>
                            <TableCell className="TableField">{ event?.description }</TableCell>
                            <TableCell className="TableField">{ convertDate(event?.date) }</TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table> 
            ) : (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>Status</TableHeaderCell>
                        <TableHeaderCell>Data</TableHeaderCell>
                        {["CORP", "TRANSPORTER"].includes(profile) ?
                        <TableHeaderCell>Entregador</TableHeaderCell> : ""}
                    </TableRow>
                </TableHead>

                <TableBody>
                    { order?.events
                        .map((event) => (
                            <TableRow key={ event?.type }>
                                <TableCell className="TableField">{ statusTranslate[event?.type] }</TableCell>
                                <TableCell className="TableField">{ convertDate(event?.date) }</TableCell>
                                {["CORP", "TRANSPORTER"].includes(profile) ?
                                <TableCell className="TableField">
                                    { (event?.id_user) && (event?.id_user in drivers) ?
                                    drivers[event?.id_user].email : "" }
                                </TableCell> : ""}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>)}
    </Card>
  );
}